<template>
  <div v-if="jobDetails !== null" class="mt-4">
    <div  class="relative mb-4 card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full ">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <span class="text-warning heading-6 pr-1"><i class="fa-solid fa-star"></i></span>
          <span class="heading-2 overFlowParaA">{{jobDetails.jobTitle}}</span>
        </div>
        <!-- <h1 class="heading-3 text-center">Job # {{jobDetails.jobNumber}}</h1> -->
        <div class=" text-primary heading-4 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="updateJobValue()">
          <i class="fa-solid fa-pen-to-square"></i>
        </div>
      </div>
      <div class=" text-primary heading-4 absolute right-2 top-2 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="updateJobValue()">
        <i class="fa-solid fa-pen-to-square"></i>
      </div>
    </div>
    <div class="mb-4 flex flex-wrap gap-4 card rounded-xl bg-white p-4">
        <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'blue1'" :btnText="'Send Booking Confirmation'" @buttonAction="sendBookingConf()"/>
        <Button v-if="!jobDetails.isClosed"  :btnSize="'medium'" :textColor="'white'" :btnColor="'warning'" :btnText="'Close Job'" @buttonAction="isCloseJobConfirm= true"/>
        <Button v-else  :btnSize="'medium'" :textColor="'white'" :btnColor="'gray3'" :btnText="'Reopen Job'" @buttonAction="isReOpenConfirm= true"/>
        <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Schedule Visit'" @buttonAction="addNewVisit"/>
        <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete Job'" @buttonAction="isDeleteJob= true"/>
        <Button v-if="this.jobDetails.jobLinkHashExpiryDate === ''" :btnSize="'medium'" :textColor="'white'" :btnColor="'secondary'" :btnText="'Generate Job Share Link'" @buttonAction="GenerateJob()"/>
        <div class="relative" v-if="this.jobDetails.jobLinkHashExpiryDate !== ''">
          <button :class="!isCard ? 'heading-7' : ''" class="bg-secondary px-3 py-2 text-white rounded-xl hover:shadow-xl" @click.stop="showMenuList = true">
            Share Job
          </button>
          <div v-if="showMenuList">
            <div v-click-outside="closeList">
              <div class=" bg-white absolute z-10 -right-10 shadow-2xl rounded top-10 w-auto">
                  <div class="p-2 text-text2 font-medium text-center">
                    <div class="p-2 cursor-pointer text-primary hover:bg-gray-50 flex items-center" @click="copyTextNoInput(jobDetails.jobShareLink)">
                      <i class="fas fa-copy"></i>
                      <span ref="jobUrl" class="py-1 pl-2" >{{jobDetails.jobShareLink}}</span>
                    </div>
                    <div class="divider"></div>
                    <div class="p-2 cursor-pointer hover:bg-gray-50" @click="sharebutton(jobDetails.jobShareLink)">
                      <span class="py-1 text-text2"><i class="fas fa-external-link text-primary heading-5 cursor-pointer"></i><span class="pl-2">Open URL</span></span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div>
      <div class="lg:grid grid-cols-3 gap-4">
        <div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class="relative">
              <div class="">
                <!-- <p class=" font-semibold mb-1 text-gray4 heading-6"><span v-if="jobDetails.isVipJob" class="text-success heading-5 pr-1"><i class="fa-solid fa-crown"></i></span> Job # {{jobDetails.jobNumber}}</p> -->
                <div class=" font-semibold mb-1 2xl:flex justify-between">
                  <p class=" font-semibold mb-1 text-gray4 heading-6">Job # {{jobDetails.jobNumber}}</p>
                  <div class="font-normal heading-7"> 
                    <div class="flex items-center justify-between text-text2">
                      <div class="mb-1 flex items-center justify-center w-fit heading-7 whitespace-nowrap border text-primary border-primary rounded px-1 py-0.5  mr-1" v-if="jobDetails.isBillable">Billable</div>
                      <p>{{jobDetails.JobTypeName}}  (Est: {{ setDisplayEstimation(jobDetails.jobEstimation) }})</p>
                      <p> <span @click="jobTypeUpdate = true" class="pl-2 cursor-pointer text-primary heading-5"><i class="fa-solid fa-pen-to-square"></i></span></p>
                    </div>
                  </div>
                  </div>
                <p v-if="jobDetails.isClosed" class=" text-warning font-semibold mb-1">Closed Job</p>
              </div>
              <div class="block">
              <div class="flex justify-between">
                <div class="">
                  <span v-if="jobDetails.customerHotNotes !== ''" class="text-error heading-4 pr-1"><i class="fa-brands fa-gripfire"></i></span>
                <span class="cursor-pointer text-primary font-semibold" @click.stop="customerDetail()">{{jobDetails.companyName}}</span>
                </div>
                <div v-if="jobDetails.customerHotNotes !== ''" class=" text-right">
                  <span class="cursor-pointer text-primary heading-7" @click.stop="showHotNOtes = !showHotNOtes"> {{showHotNOtes ? 'Hide' : 'Show'}} hot notes</span>
                </div>
              </div>
              <div v-if="showHotNOtes" class="">
                  <span class="cursor-pointer text-secondary">
                    {{jobDetails.customerHotNotes}}
                  </span>
                </div>
              <div v-if="jobDetails.customerContactNumber !== ''" class=" flex justify-between mt-2">
                <span>{{jobDetails.customerContactNumber | phone}}</span>
                <div class="flex gap-4">
                  <a class="text-primary" :href="`tel:${jobDetails.customerContactNumber}`"><i class="fa-solid fa-phone-volume"></i></a>
                <a class="text-primary" :href="`sms:${jobDetails.customerContactNumber}`"><i class="fa-solid fa-comment"></i></a>
                </div>
              </div>
              </div>
              <!-- <span @click="updateJobValue()" class="text-primary cursor-pointer absolute right-0 top-0"><i class="fa-solid fa-pen-to-square"></i></span> -->
            </div>
          </div>
          <div  v-if="jobDetails.nextJobVisitDetail.visitStartDateTime !== '' && visitCountVal > 1" class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
            <p class="text-text1 font-bold mb-2 heading-4 ">Next Visit</p>
            <div class="cursor-pointer hover:bg-gray1" @click="$router.push({name: 'visitDetail', params: {visitId: jobDetails.nextJobVisitDetail.visitId}})">
              <p>
                <span v-if="jobDetails.nextJobVisitDetail.dateStatus === 'Late'" class="text-error h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                <span v-if="jobDetails.nextJobVisitDetail.dateStatus === 'Today'" class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                <span v-if="jobDetails.nextJobVisitDetail.dateStatus === 'Upcoming'" class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                {{jobDetails.nextJobVisitDetail.visitStartDateTime | DatePickerDateHeader}} </p>
              <p class="text-gray4 heading-6">
                <span>{{jobDetails.nextJobVisitDetail.visitStartDateTime | TimeOnlyFilter}}</span> -
                <span>{{jobDetails.nextJobVisitDetail.visitEndDateTime | TimeOnlyFilter}}</span>
              </p>
            </div>
            </div>
          </div>
          <div  v-if="jobDetails.isUnschedule" class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <p>
              <span class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
              <span class="text-secondary heading-4 pl-2">Unscheduled Job</span>
            </p>
          </div>
          <div v-if="jobDetails.latitude !== 0 && jobDetails.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
              <div class="flex justify-between items-center mb-2">
                  <p class="font-bold">Address</p>
              </div>
              <div class="">
                  <!-- <div class="mb-4 flex whitespace-nowrap items-start relative" >
                    <div>
                      <span @click="openMap(jobDetails.latitude, jobDetails.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                    </div>
                    <div class="pl-1">
                        <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-line" v-if="jobDetails.fullAddress === ''">
                        {{jobDetails.addressLine1}}
                        </p>
                        <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-line" v-if="jobDetails.fullAddress !== ''">
                        {{jobDetails.fullAddress}}
                        </p>
                        <p  class="text-text1 heading-6">
                          <span v-if="jobDetails.houseNo !== ''">#: {{jobDetails.houseNo}}</span>
                          <span v-if="jobDetails.houseNo !== ''"> (Floor: {{jobDetails.floorNumber}})</span>
                        </p>
                        <p v-if="jobDetails.buildingName!== ''" class="text-text1 whitespace-pre-line">Bldg: {{jobDetails.buildingName}}</p>
                        <p v-if="jobDetails.directionNote!== ''" class="text-text1 whitespace-pre-line">Directions: {{jobDetails.directionNote}}</p>
                        <p v-if="jobDetails.contactName!== ''" class="text-text1 whitespace-pre-line">Contact: {{jobDetails.contactName}}</p>
                        <p v-if="jobDetails.contactNumber!== ''" class="text-text1 whitespace-pre-line">Phone #: {{jobDetails.contactNumber | phone}}</p>
                    </div>
                    <span @click="updateJobAddress()" class="text-primary cursor-pointer absolute right-0"><i class="fa-solid fa-pen-to-square"></i></span>
                  </div> -->
                  <div class="flex whitespace-nowrap justify-between">
                    <div class="flex items-start">
                      <div>
                        <span @click="openMap(jobDetails.latitude, jobDetails.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2">:</span></span> 
                      </div>
                      <div class="pl-1 relative">
                        
                          <p v-if="jobDetails.fullAddress !== ''" class="pr-4   font-bold text-text2 whitespace-pre-wrap cursor-pointer" @click="showJobDetail = !showJobDetail">
                           <span class=" border border-gray2 rounded-md whitespace-nowrap heading-7 px-1 py-0.5 mr-1">
                            {{jobDetails.addressTitle === '' ? 'Other' : jobDetails.addressTitle}}
                          </span>  {{jobDetails.fullAddress}}
                          </p>
                          <p v-if="jobDetails.fullAddress === ''" class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap cursor-pointer" @click="showJobDetail = !showJobDetail">
                          {{jobDetails.addressLine1}}
                          </p>
                          <div v-if="showJobDetail">
                            <p  class="text-text1 heading-6">
                              <span v-if="jobDetails.houseNo !== ''">#: {{jobDetails.houseNo}}</span>
                              <span v-if="jobDetails.floorNumber !== ''"> (Floor: {{jobDetails.floorNumber}})</span>
                            </p>
                            <p v-if="jobDetails.buildingName!== ''" class="text-text1 ">Bldg: {{jobDetails.buildingName}}</p>
                            <p v-if="jobDetails.directionNote!== ''" class="text-text1 whitespace-pre-wrap">Directions: {{jobDetails.directionNote}}</p>
                            <!-- <div class="p-1">
                              <span @click="updateJobAddress()" class="text-primary cursor-pointer heading-5"><i class="fa-solid fa-pen-to-square"></i></span>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="flex items-start">
                        <span @click="updateJobAddress()" class="text-primary cursor-pointer"><i class="fa-solid fa-pen-to-square"></i></span>
                        <div class="ml-1" v-if="jobDetails.houseNo !== '' || jobDetails.floorNumber !== '' || jobDetails.buildingName!== '' || jobDetails.directionNote!== ''">
                          <span @click="showJobDetail = !showJobDetail" v-if="showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-up"></i></span>
                        <p @click="showJobDetail = !showJobDetail" v-if="!showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-down"></i></p>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div v-else class="card bg-white rounded-xl p-4 mb-4">
              <div class="">
                  <div class="flex whitespace-nowrap items-start relative" >
                    <div>
                      <span @click="openMap(orgDetail.latitude, orgDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                    </div>
                    <div class="pl-1">
                      <p class="text-gray4 heading-5">At Office</p>
                    </div>
                    <span @click="updateJobAddress()" class="text-primary cursor-pointer absolute right-0"><i class="fa-solid fa-pen-to-square"></i></span>
                  </div>
              </div>
          </div>
          <!-- <div v-else class="card bg-white rounded-xl p-4 mb-4">
              <div class="flex justify-between items-center">
                <p class="text-gray4 heading-5">No Location Selected</p>
                <p class="text-primary heading-5 cursor-pointer" @click="addNewAddress()">+ Add Location</p>
              </div>
          </div> -->
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p v-if="jobDetails.jobDescription !== ''" class="  font-bold mb-2">Instructions</p>
              <p v-else class="  font-bold">No Instructions</p>
              <span class="mb-0 text-gray4 whitespace-pre-line">
                {{jobDetails.jobDescription === '' ? '-' : (jobDetails.jobDescription.length > 125)? readMoreNote ?  jobDetails.jobDescription : jobDetails.jobDescription.substr(0, 125) : jobDetails.jobDescription}}
                <span v-if="jobDetails.jobDescription.length > 125" class="text-primary cursor-pointer heading-7" @click="readMoreNote = !readMoreNote">
                  {{readMoreNote === false ? 'Read More...' : 'Read Less...'}}</span>
              </span>
              <!-- <p class="text-gray4 whitespace-pre-line">{{jobDetails.jobDescription}}</p> -->
              <span @click="getsJobDetail()" class="pl-2 cursor-pointer text-primary absolute top-0 right-7"><i class="fa-solid fa-refresh"></i></span>
              <span @click="updateJob('notes', jobDetails.jobDescription, jobDetails.jobDescAttachmentDetail.attachmentList)" class="pl-2 cursor-pointer text-primary absolute top-0 right-0"><i class="fa-solid fa-pen-to-square"></i></span>
            </div>
            <div v-if="jobDetails.jobDescAttachmentDetail.attachmentList !== null" class="mt-2">
               <!-- <p class="text-primary heading-6 mb-2">Attachments:</p> -->
               <div class="flex gap-3" style="flex-wrap: wrap;">
                 <div class=" card rounded-sm border-t border-gray-100 bg-white attachment-card cursor-pointer group relative" style="width: 110px; height: 76px"  v-for="(attach, a) in jobDetails.jobDescAttachmentDetail.attachmentList " :key="a" @click="showFullImage(attach)">
                    <div v-if="tekeType(attach.fileType) === 'image'" class="text-center flex justify-center">
                        <img style="height: 60px;width: auto;" :src="`${jobDetails.jobDescAttachmentDetail.pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                        <!-- <div class="invisible group-hover:visible absolute bg-gray4 w-full min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex heading-4">{{ attach.uploadedOn | dateTimeFilter}}</div> -->
                        <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                    </div>
                    <div v-if="tekeType(attach.fileType) === 'video'" class="flex justify-center items-center w-full">
                        <img style="height: 60px;width: auto;" :src="`${jobDetails.jobDescAttachmentDetail.pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                        <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                        <!-- <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.uploadedOn | dateTimeFilter}}</div> -->
                        <p v-if="attach.addedById === 0"><i  class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                    </div>
                    <div v-if="tekeType(attach.fileType) === 'pdf'" class="flex justify-center items-center w-full">
                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                        <!-- <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.uploadedOn | dateTimeFilter}}</div> -->
                        <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                    </div>
                    <div v-if="tekeType(attach.fileType) !== 'image' && tekeType(attach.fileType) !== 'video' && tekeType(attach.fileType) !== 'pdf'" class="flex justify-center items-center w-full">
                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                        <!-- <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.uploadedOn | dateTimeFilter }}</div> -->
                        <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                    </div>
                    <div class="text-text2 text-center heading-8">{{attach.uploadedOn | dateFilter1}}</div>
                  </div>
               </div>
            </div>
          </div>
          <!-- <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p class="  font-bold">Job Type:</p>
              <p class="text-gray4 whitespace-pre-line">{{jobDetails.JobTypeName}}</p>
              <p class="  font-bold mt-1">Job Estimation:</p>
              <p class="text-gray4 whitespace-pre-line">{{setDisplayEstimation(jobDetails.jobEstimation)}}</p>
              <span @click="jobTypeUpdate = true" class="pl-2 cursor-pointer text-primary absolute top-0 right-0"><i class="fa-solid fa-pen-to-square"></i></span>
            </div>
          </div> -->
        </div>
        <div class="col-span-2">
          <div  class="card rounded-xl bg-white mb-4 p-4" v-if="!jobDetails.isUnschedule">
            <VisitList />
          </div>
          <div class="card bg-white rounded-xl p-4 mb-4">
              <p class=" text-text1 heading-4 font-bold mb-2"> Invoice</p>
              <div style="overflow-y: auto; white-space: nowrap;" class="">
                <div v-if="jobInvoiceLi.length > 0">
                    <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
                        <div class="table_col w-32  flex items-center">
                        <span class="">{{titleObj[0].title}}</span>
                        </div>
                        <div class="table_col w-44  flex items-center">
                        <span class="">{{titleObj[1].title}}</span>
                        </div>
                        <div class="table_col w-44  flex items-center">
                        <span class="">{{titleObj[2].title}}</span>
                        </div>
                        <div class="table_col w-32  flex items-center">
                        <span class="">{{titleObj[3].title}}</span>
                        </div>
                        <div class="table_col w-32  flex items-center">
                        <span class="">{{titleObj[4].title}}</span>
                        </div>
                    </div>
                        
                        <div v-for="(data, index) in jobInvoiceLi" :key="index" @click="openInvoiceDetails(data.invoiceId)" :class="`${jobInvoiceLi.length - 1 > index  ? 'border-b border-gray1' : ''}`" class="min-w-min flex heading-5  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer">
                        <div class="table_col w-32 ">
                            <div class="">
                            <p class="  text-text2">#{{data.invoiceNumber}}</p>
                            </div>
                        </div>
                        <div class="table_col w-44 ">
                            <div class="flex items-center">
                            <span class="  text-gray4 heading-6">{{data.grandTotal | amountOnlyNumber}}</span>
                            </div>
                        </div>
                        <div class="table_col w-44">
                            <div class="flex items-center">
                            <span class="  text-gray4 heading-6">{{data.invoiceDate | formatForDatePicker}}</span>
                            </div>
                        </div>
                        <div class="table_col w-32 ">
                            <div class="flex items-center">
                            <span class="  text-gray4 heading-6">{{data.dueDate | formatForDatePicker}}</span>
                            </div>
                        </div>
                        <div class="table_col w-32 ">
                            <div class="flex items-center">
                            <span :style="`color: ${data.statusColor};`" class=" text-gray4 heading-6">{{data.statusName}}</span>
                            </div>
                        </div>
                        </div>
                </div>
                <div v-else>
                  <NoRecords :alertMessage="` No records found.`" />
                </div>
              </div>
          </div>
          <div class="card p-4 bg-white rounded-xl mb-4">
            <LineItems :detailData="{
              customerId:jobDetails.customerId,
              jobId:jobDetails.jobId,
              visitId:0,
              jobTitle: jobDetails.jobTitle,
              visitNumber: 0,
              requestType: 'job',
              }" />
          </div>
          <!-- <div  class="card rounded-xl bg-white mb-4 p-4">
            <NotesList :detailId="parseInt(this.$route.params.jobId)" :requestType="'job'" :noteTitle="'Job Note'" />
          </div> -->
        </div>
      </div>
    </div>
    <FullImage :fileDate="fileDate" v-if="shoFullImage" :fileVaultId="fileVaultId" :fileType="fileType" :fileName="fileName" :isFromContactId="isFromContactId" />  
    <AddNewVisit v-if="isAddNewVisit" :detail="jobDetails"/>
    <UpdateJobTitleCust v-if="updateJobTitle" :detail="jobDetails" />
    <UpdateInsruction v-if="updateInstruction" :pathPrefix="jobDetails.jobDescAttachmentDetail.pathPrefix" :note="updateValue" :attachment="insArray" :reqType="reqType" />
    <UpdateJobType v-if="jobTypeUpdate" :detail="jobDetails" :reqType="'jobType'" @updateJobTypeHandler="updateJobTypeHandler" />
    <SendBokkingCon :entitySlug="'job'" :detailId="parseInt(this.$route.params.jobId)" :customerId="jobDetails.customerId" v-if="isSendBookConf"  />
    <ConfirmBox v-if="isCloseJobConfirm"  :message="'Closing job will automatically completed visit that are not completed.'" :title="'Close Job?'" />
    <ConfirmBox v-if="isReOpenConfirm"  :message="''" :rbtnColor="'warning'" :title="'Are you sure to Reopen this job?'" />
    <ConfirmBox v-if="isDeleteJob" :yesConfir="true" :inputLable='`Type "Yes" in below text box to delete job`'  :message="'Deleting job will also deleted all the linked visit including notes, attachment and time logs.'" :title="'Close Job?'" />
    <AddressAdd :showAdditional="true" v-if="addNewjobAdd" />
    <UpdateJobAddress :jobDetail="addressObject" v-if="editJobAddress" :showAdditional="true" />
    <!-- <EditAddress :jobDetail="addressObject" v-if="editJobAddress" :showAdditional="true" /> -->
  </div>
</template>
<script>
var moment = require('moment')
import AddNewVisit from "@/View/job/visit/components/AddNewVisit.vue";
import AddressAdd from '@/View/components/AddressAdd.vue'
import Button from '@/View/components/globalButton.vue'
import SendBokkingCon from '@/View/components/SendBokkingCon.vue'
import MyJobApp from "@/api/MyJobApp.js";
import VisitList from '@/View/job/visit/VisitList.vue';
import ConfirmBox from '@/View/components/ConfirmBox.vue'
// import NotesList from '@/View/job/visit/NotesList.vue';
import UpdateJobTitleCust from '@/View/job/components/UpdateJobTitleCust.vue';
import UpdateInsruction from './components/UpdateInsruction.vue';
import UpdateJobType from './components/UpdateJobType.vue';
import {FilterPermissions} from '@/utils/Permissions.js'
// import EditAddress from '../components/EditAddress.vue';
import UpdateJobAddress from './components/UpdateJobAddress.vue';
import LineItems from './visit/components/LineItems.vue';
import NoRecords from '../components/noRecords.vue';
import FullImage from '../endUserNewFlow/components/FullImage.vue';

export default {
  name: "lead",
  components: {
    AddNewVisit,
    Button,
    // EditAddress,
    AddressAdd,
    VisitList,
    // NotesList,
    UpdateJobTitleCust,
    UpdateInsruction,
    ConfirmBox,
    SendBokkingCon,
    UpdateJobAddress,
    UpdateJobType,
    LineItems,
    NoRecords,
    FullImage,
  },
  data() {
    return {
      fileDate: '',
      isFromContactId: 0,
      readMoreNote: false,
      showJobDetail: false,
      isCard: true,
      visitCountVal: 0,
      attachmentArray: [],
      linkActive: '',
      showMenuList: false,
      isCustomerPermission: FilterPermissions('customer'),
      orgDetail: {},
      isAddNewVisit: false,
      editJobAddress: false,
      addNewjobAdd: false,
      jobDetails: null,
      addressObject: null,
      updateJobTitle: false,
      updateInstruction: false,
      isCloseJobConfirm: false,
      isReOpenConfirm: false,
      isDeleteJob: false,
      isSendBookConf: false,
      reqType: '',
      updateValue: '',
      insArray: [],
      jobTypeUpdate: false,
      showHotNOtes: false,
      selectedItemList: [],
      jobInvoiceLi: [],
      jobInVoiceCount: 0,
      titleObj: [
          {title: 'Invoice Number', icon: '', sortName: ''},
          {title: 'Invoice Total', icon: '', sortName: ''},
          {title: 'Date', icon: '', sortName: ''},
          {title: 'Due Date', icon: '', sortName: ''},
          {title: 'Status', icon: '', sortName: ''},
        ],
        fileVaultId: 0,
        fileType: '',
        fileName: '',
        shoFullImage: '',
    };
    
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler()
    this.invoiceListOfJob()
    setTimeout(() => {
      let temp = FilterPermissions('job')
      if (temp) {
        this.getsJobDetail(true)
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to show Job detail`})
      }
    }, 200);
      this.$root.$on('updateAddressHandler', (response, data) => {
      if (response && data !== null && this.editJobAddress) {
        this.addJobApi(data)
      }
      this.editJobAddress = false
    })
    this.$root.$on('addNewVisitHandler', (response) => {
      console.log('response 12', response)
      if (response) {
        this.getsJobDetail()
      }
      this.isAddNewVisit = false
    })
    this.$root.$on('addAddressHandler', (response, data) => {
      if (response && data !== null && this.addNewjobAdd) {
        this.addJobApi(data)
      }
      this.addNewjobAdd = false
    })
    this.$root.$on('visitCount', (response) => {
      this.visitCountVal = response
    })
    this.$root.$on('EditAddressHandler', (response, data) => {
      if (response && data !== null && this.editJobAddress) {
        this.addJobApi(data)
        this.editJobAddress = false
      }
    })
    this.$root.$on('updateJobHandler', (data) => {
      if (data) {
        this.getsJobDetail()
        }
        this.updateJobTitle = false
        this.updateInstruction = false
    })
    this.$root.$on('bookingConfirmationHandler', () => {
      document.body.style = 'overflow: visible;'
        this.isSendBookConf = false
    })
    this.$root.$on('confirmBoxHandler', (data) => {
      if (data && this.isCloseJobConfirm) {
        this.closeJobFinal()
      }
      if (data && this.isReOpenConfirm) {
        this.reOpenJobs()
      }
      if (data && this.isDeleteJob) {
        this.deleteJobFinal()
      }
      document.body.style = 'overflow: visible;'
      this.isReOpenConfirm = false
      this.isCloseJobConfirm = false
      this.isDeleteJob = false
    })
     this.$root.$on('fullImageHandlerEnd', (response) => {
       console.log('response', response);
        this.shoFullImage = false
    })
  },
  beforeDestroy() {
    this.$root.$off("fullImageHandlerEnd");
    this.$root.$off("updateAddressHandler");
    this.$root.$off("EditAddressHandler");
    this.$root.$off("addNewVisitHandler");
    this.$root.$off("addAddressHandler");
    this.$root.$off("visitCount");
    this.$root.$off("updateJobHandler");
    this.$root.$off("bookingConfirmationHandler");
    this.$root.$off("confirmBoxHandler");
  },
  methods: {
    resizeWindowHandler() {
      console.log('window.innerWidth', window.innerWidth)
      this.screenWidth = window.innerWidth
      if (window.innerWidth <= 640) {
        this.isCard = false
      } else {
        this.isCard = true
      }
    },
    tekeType (fileType) {
      let temp = fileType.split('/')
        console.log('temp', temp)
        let type = ''
        if (temp[0] === 'image' ) {
            type = 'image'
        } else if (temp[0] === 'application' && temp[1] === 'pdf') {
          type = 'pdf'
        } else if (temp[0] === 'video' ) {
          type = 'video'
        }
        return type
    },
    openInvoiceDetails (invoiceId) {
      this.$router.push({name: 'invoiceDetails', params: {invoiceId: invoiceId}})
    },
    invoiceListOfJob () {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.invoiceListOfVisitsAndJobWeb(
        0,
        50,
        parseInt(this.$route.params.jobId),
        (response) => {
          console.log('response*************************************', response);
          this.jobInvoiceLi = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.jobInVoiceCount = response.Data.count
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            console.log('error', error);
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    closeList () {
      this.showMenuList = false
    },
    reOpenJobs () {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.reopenJob(
        parseInt(this.$route.params.jobId),
        (response) => {
            document.body.style = 'overflow: visible;'
            this.getsJobDetail()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            console.log('error', error);
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    selectText (element) {
      var range
      if (document.selection) {
        range = document.body.createTextRange()
        range.moveToElementText(element)
        range.select()
      } else if (window.getSelection) {
        range = document.createRange()
        range.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
    },
    copyTextNoInput () {
      this.selectText(this.$refs.jobUrl)
      document.execCommand('copy')
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'Job url copied', color: 'success'})
    },
    sharebutton (data) {
      // // let url = this.$router.resolve({name: 'jd', params: {infoId: 0}})
      // let url = this.$router.resolve({name: 'jd', params: {infoId: 0}})
      // window.open(url.href, '_blank')
      window.open(data, '_blank')
    },
    GenerateJob () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Generating...'})
      MyJobApp.GenerateJobShareLink(
        this.jobDetails.jobId,
        (response) => {
          this.getsJobDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    getJobsItemList () {
      this.subTotal = 0
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getVisitItemList(
        0,
        this.jobDetails.jobId,
          response => {
            this.selectedItemList = response.Data.tableRow !== null ? response.Data.tableRow : []
            console.log('++++++++++++++++++++++++++++++++++++++++++++++', response.Data.tableRow);
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    updateJobTypeHandler (response) {
      if (response) {
        this.getsJobDetail()
      }
      this.jobTypeUpdate = false
    },
    setDisplayEstimation (value) {
      let estimation = value
      let hours = Math.floor(estimation / 60); // Get the whole number of hours
      let min = estimation % 60; // Get the remaining minutes
      let minutes = Math.round(min); // Get the remaining minutes
      let formattedTime = `${ hours > 0 ? hours.toString().padStart(2, '0') + ' hr' : ''} ${hours > 0 && minutes > 0 ? "," : ''} ` + ` ${minutes > 0 ? ` ${minutes.toString().padStart(2, '0')} mins` : ''}`;

      return formattedTime
    },
    customerDetail () {
      if (this.isCustomerPermission) {
        this.$router.push({name: 'customerDetails', params: {customerId: this.jobDetails.customerId}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to Open Customer Detail`})
      }
    },
    addNewAddress () {
      this.addNewjobAdd = true
    },
    addNewVisit () {
      this.isAddNewVisit = true
      // this.$root.$emit('fromDetailScheduleVisit', true)
    },
    sendBookingConf () {
      document.body.style = 'overflow: hidden;'
      this.isSendBookConf = true
    },
    updateJobAddress () {
      this.editJobAddress = true
    },
    updateJobValue () {
      this.updateJobTitle = true
    },
    updateJob (type, value, data) {
      this.reqType = type
      this.updateValue = value
      if (data !== null) {
        this.insArray = this.attachmentArray
      }
      this.updateInstruction = true
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
    addJobApi (data) {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading..." });
      let SendObj = {
        jobId: parseInt(this.$route.params.jobId),
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country,
        latitude: data.latitude,
        longitude: data.longitude,
        geofenceRangeDiameter: data.selectedRadius,
        fullAddress: data.fullAddress,
        houseNo: data.houseNo,
        floorNumber: data.floorNumber,
        buildingName: data.buildingName,
        directionNote: data.directionNote,
        contactNumber: data.contactNumber,
        contactName: data.contactName,
        addressTitle: data.addressTitle
      }
      MyJobApp.addNewJobLocationWeb(
        SendObj,
        (response) => {
          this.addNewjobAdd = false
          this.getsJobDetail()
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
      // addNewJobLocationWeb
    },
    getsJobDetail(getItems) {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.getJobDetail(
        parseInt(this.$route.params.jobId),
        (response) => {
          this.jobDetails = response.Data;
          this.addressObject = response.Data
          let currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          currentDate = new Date(currentDate);
          const cFormated = moment(currentDate).format('YYYY-MM-DD');
          if ( moment(new Date(this.jobDetails.nextJobVisitDetail.visitStartDateTime)).format('YYYY-MM-DD',) === cFormated) {
            this.jobDetails.nextJobVisitDetail.dateStatus = 'Today';
          } else if ( new Date(this.jobDetails.nextJobVisitDetail.visitStartDateTime).getTime() < currentDate.getTime()) {
            this.jobDetails.nextJobVisitDetail.dateStatus = 'Late';
          } else {
            this.jobDetails.nextJobVisitDetail.dateStatus = 'Upcoming';
          }
          if (this.jobDetails.jobDescAttachmentDetail.attachmentList !== null) {
            this.attachmentArray = []
            this.jobDetails.jobDescAttachmentDetail.attachmentList.map(a => {
                let temp = a.fileType.split('/')
                console.log('temp', temp)
                let type = ''
                if (temp[0] === 'image' ) {
                    type = 'image'
                } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                  type = 'pdf'
                } else if (temp[0] === 'video' ) {
                  type = 'video'
                }
                this.attachmentArray.push({...a, docType: type})
            })
          }
          if (this.jobDetails.latitude === 0 && this.jobDetails.longitude === 0) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
            MyJobApp.getOrgDetail(
              (response) => {
                this.orgDetail = response.Data
                this.addressObject = this.orgDetail
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              },
              (err) => {
                this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              }
            );
          }
          var startTime = moment(new Date());
          var endTime = moment(new Date(this.jobDetails.jobLinkHashExpiryDate));
          console.log('diffInHours startTime', startTime);
          console.log('diffInHours endTime', endTime);

          if (endTime.isBefore(startTime)) {
            this.jobDetails.jobShareLink = '';
            this.jobDetails.jobLinkHashExpiryDate = '';
          }
          if (getItems) {
            this.getJobsItemList()
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    closeJobFinal() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.closeJob(
        parseInt(this.$route.params.jobId),
        (response) => {
            document.body.style = 'overflow: visible;'
            this.getsJobDetail()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            console.log('error', error);
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    deleteJobFinal() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.deleteJob(
        parseInt(this.$route.params.jobId),
        (response) => {
            document.body.style = 'overflow: visible;'
            this.$router.go(-1)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            console.log('error', error);
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    showFullImage (data) {
        this.fileVaultId = data.fileVaultId
        this.fileType = this.tekeType(data.fileType)
        this.fileName = data.displayFileName
        this.isFromContactId = data.addedById
        this.fileDate = data.uploadedOn
        this.shoFullImage = true
    },
  },
};
</script>
<style scoped>
.scroll-img-view{
  height: 104px;
}
.zIndex {
  z-index: 100 !important;
}
</style>